'use client'

import Link from 'next/link'
import { useEffect, useState } from 'react'

export const Popup = () => {
  const [mountState, setMountState] = useState('unmounted')

  useEffect(() => {
    const isPopupClosed = sessionStorage.getItem('isPopupClosed')

    if (isPopupClosed !== 'true') {
      setMountState('mounting')
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          setMountState('mounted')
        })
      })
    }
  }, [])

  const handleClose = () => {
    setMountState('unmounting')
    setTimeout(() => {
      setMountState('unmounted')
      sessionStorage.setItem('isPopupClosed', 'true')
    }, 400)
  }

  const PopupContent = () => (
    <div className="relative rounded-lg overflow-hidden w-full hidden lg:block">
      <Link
        className="w-full block"
        target="_blank"
        href="/demo/008/?from=popup"
      >
        <div className="self-stretch h-auto flex-row lg:flex-col justify-start lg:justify-end items-center gap-5 lg:gap-4 flex overflow-hidden">
          <div className="bg-[#efece5]">
            <img
              className="hidden w-[300px] self-stretch lg:inline-block"
              src="/images/popups/img_popup.jpg"
              alt="ナレッジAIレビュー"
            />
            <img
              className="w-[129px] self-stretch lg:hidden"
              src="/images/popups/img_popup_mobile.jpg"
              alt="ナレッジAIレビュー"
            />
          </div>
          <div className="flex-col justify-start items-start lg:items-center gap-1 lg:gap-5 flex">
            <div className="flex-col justify-start items-start lg:items-center gap-3 flex text-left lg:text-center font-bold tracking-wide">
              <div>
                <span className="text-sm lg:text-base text-primary border-b-2 border-primary">
                  ナレッジAIレビュー機能
                </span>
                <span className="text-sm">を</span>
                <span className="text-sm block lg:inline-block mt-1 lg:mt-0">
                  提供開始！
                </span>
              </div>
              {/* <div className="text-sm hidden lg:block">
                  <span className="mr-2 text-base">\</span>
                  特別セミナーの予約受付中
                  <span className="ml-2 text-base">/</span>
                </div> */}
            </div>
            <div className="lg:h-9 lg:px-6 lg:py-2 lg:bg-primary lg:rounded-[30px] justify-center items-center gap-1 inline-flex">
              <div className="text-center text-secondary lg:text-white text-xs lg:text-sm font-bold">
                今すぐデモ体験
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  )

  return (
    <>
      {/* セッションストレージ依存のポップアップ */}
      {mountState !== 'unmounted' && (
        <div
          id="session"
          className={`
            w-[344px] lg:w-[300px] h-auto lg:pb-6
            bg-white rounded-lg border border-gray lg:border-0 lg:drop-shadow-2xl
            flex-col justify-start items-start gap-4 my-6 lg:my-0
            fixed bottom-4 left-4
            transition-all duration-[400ms] ease-out-expo
            transform hidden lg:inline-flex
            ${mountState === 'mounting' ? 'translate-y-full opacity-0' : ''}
            ${mountState === 'mounted' ? 'translate-y-0 opacity-100' : ''}
            ${mountState === 'unmounting' ? 'translate-y-full opacity-0' : ''}
          `}
          style={{
            willChange: 'transform, opacity'
          }}
        >
          <div className="absolute -right-4 -top-5 z-50 hidden lg:inline-block">
            <button
              className="flex items-center justify-center border-2 border-primary bg-white p-2 rounded-full hover:border-secondary hover:fill-secondary transition-colors duration-150 group w-[40px] h-[40px]"
              aria-label="Close"
              onClick={handleClose}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="fill-primary group-hover:fill-secondary transition-colors duration-150"
              >
                <path d="M17.469 5.53639L18.4634 6.53076C18.5518 6.61915 18.5518 6.70754 18.4634 6.79593L6.79612 18.4632C6.70773 18.5516 6.61934 18.5516 6.53095 18.4632L5.53658 17.4688C5.44819 17.3804 5.44819 17.292 5.53658 17.2037L17.2038 5.53639C17.2922 5.448 17.3806 5.448 17.469 5.53639Z" />
                <path d="M7.06129 5.80153L18.1982 16.9385C18.2866 17.0268 18.2866 17.1152 18.1982 17.2036L17.2039 18.198C17.1155 18.2864 17.0271 18.2864 16.9387 18.198L5.80176 7.06106C5.71337 6.97267 5.71337 6.88429 5.80176 6.7959L6.79613 5.80153C6.88452 5.71314 6.97291 5.71314 7.06129 5.80153Z" />
              </svg>
            </button>
          </div>
          <PopupContent />
        </div>
      )}

      {/* セッションストレージ非依存のポップアップ（常に表示） */}
      <div
        id="noSession"
        className="w-[344px] lg:w-[300px] h-auto lg:pb-6 bg-white rounded-lg border border-gray lg:border-0 lg:drop-shadow-2xl flex-col justify-start items-start gap-4 my-6 lg:my-0 relative hidden"
      >
        <PopupContent />
      </div>
    </>
  )
}

Popup.displayName = 'Popup'

export default Popup
